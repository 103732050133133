




























import Vue from 'vue';
import PeopleIcon from '@/assets/icon/benefits/people.svg';
import ThumbsUpIcon from '@/assets/icon/benefits/thumbs-up.svg';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {ISlide} from '@/services/api/head-slider.service';

@Component({
  components: {
    PeopleIcon,
    ThumbsUpIcon
  }
})
export default class Slide extends Vue {
  @Prop({
    required: true,
    type: Object
  })
  private item!: ISlide;
}
