

















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {IMedia, MediaType, MediaTypes} from '@/services/api/media.service';
import {MediaTypeTitle, MediaTypeIcon} from '@/components/UI/Card/Card.vue';
import stringToHtml from '@/filters/stringToHtml';

import PlusIcon from '@/assets/icon/ico-plus.svg';
import DownloadIcon from '@/assets/icon/ico-download.svg';
import FilmIcon from '@/assets/icon/film.svg';
import SoundIcon from '@/assets/icon/ico-sound.svg';

import BackLink from '@/components/UI/BackLink.vue';
import Chevron from '@/components/UI/Icons/Chevron.vue';
import CardSlider from '@/pages/Main/Card.slider.section.vue';
import DetailsLabels from '@/pages/Details/DetailsLabels.block.vue';
import DetailsMainInfo from '@/pages/Details/DetailsMainInfo.block.vue';
import DetailsButtons from '@/pages/Details/DetailsButtons.block.vue';
import Permission from '@/components/Permission/Permission.vue';

@Component({
  components: {
    CardSlider,
    DetailsLabels,
    DetailsMainInfo,
    DetailsButtons,
    Chevron,
    BackLink,
    FilmIcon,
    SoundIcon,
    PlusIcon,
    DownloadIcon,
    Permission
  },
  filters: { stringToHtml }
})
export default class DetailsStream extends Vue {
  private FilmIcon = FilmIcon;
  private PlusIcon = PlusIcon;
  private DownloadIcon = DownloadIcon;

  private isPlayActivated: boolean = false;

  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;

  public MediaTypes = MediaTypes;

  public type = this.data.loanableMedia
    ? this.data.loanableMediaType
    : MediaTypeTitle[this.data.mediaType as MediaType];
    
  public icon = this.data.loanable
    ? null
    : MediaTypeIcon[this.data.mediaType as MediaType];

  private onPlay() {
    if (!this.isPlayActivated) {
      this.$store.dispatch('sendStatisticStream', this.data.id);
      this.isPlayActivated = true;
    }
  }
}
