

































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {IMedia, MediaType} from '@/services/api/media.service';
import {MediaTypeIcon, MediaTypeTitle} from '@/components/UI/Card/Card.vue';

import ZoomIcon from '@/assets/icon/zoomin.svg';

import BackLink from '@/components/UI/BackLink.vue';
import Chevron from '@/components/UI/Icons/Chevron.vue';
import TextCollapse from '@/components/UI/TextCollapse.vue';
import CardSlider from '@/pages/Main/Card.slider.section.vue';
import DetailsLabels from '@/pages/Details/DetailsLabels.block.vue';
import DetailsButtons from '@/pages/Details/DetailsButtons.block.vue';
import DetailsMainInfo from '@/pages/Details/DetailsMainInfo.block.vue';
import Lightbox from '@/components/UI/Lightbox.vue';
import Permission from '@/components/Permission/Permission.vue';

import stringToHtml from '@/filters/stringToHtml';

@Component({
  components: {
    TextCollapse,
    CardSlider,
    DetailsLabels,
    DetailsButtons,
    DetailsMainInfo,
    Lightbox,
    Chevron,
    BackLink,
    Permission,
    ZoomIcon
  },
  filters: { stringToHtml }
})
export default class DetailsDocument extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;

  public type = this.data.loanableMedia
    ? this.data.loanableMediaType
    : this.data.mediaType === 'ARBEITSMATERIAL' && this.data.learningObjectTypes?.length
      ? this.data.learningObjectTypes?.join(', ')
      : MediaTypeTitle[this.data.mediaType as MediaType];
    
  public icon = this.data.loanable
    ? null
    : MediaTypeIcon[this.data.mediaType as MediaType];

  public view() {
    if (this.data.streaming) {
      this.$store.dispatch(
        'openLightbox',
        {
          url: this.$store.getters.isMediaDocumentViewAvailable(this.data)
            ? this.data.dataInfo.versions.original.url
            : this.data.dataInfo.versions.preview.url,
          component: this.$store.getters.isMediaDocumentViewAvailable(this.data)
            ? 'pdf' 
            : 'image'
        }
      );
    }
  }
}
