




















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IRotateParameters} from '@/services/data/benefit.service';

@Component({})
export default class BenefitsImage extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public translate!: string;
  @Prop({
    type: Object,
    required: true,
  })
  public blueTriangleRotateParameters!: IRotateParameters;
  @Prop({
    type: Object,
    required: true,
  })
  public whiteTriangleRotateParameters!: IRotateParameters;
  @Prop({
    type: Object,
    required: true,
  })
  public icon!: string;
}
