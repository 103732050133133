





import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Card from '@/components/UI/Card/Card.vue';

@Component({
  components: {
    Card
  }
})
export default class CardSlide extends Vue {
  @Prop({
    required: true,
    type: Object
  })
  public item!: object;
}
