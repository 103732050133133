

























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import Badge from '@/components/UI/Badge.vue';
import Chevron from '@/components/UI/Icons/Chevron.vue';
import HeightTransition from '@/components/UI/HeightTransition.vue';

@Component({
  components: {
    Badge,
    Chevron,
    HeightTransition
  }
})
export default class AccordionItem extends Vue {
  public isExpanded: boolean = false;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public title!: string;

  @Prop({
    type: Number,
    required: false,
    default: 0
  })
  public count!: number;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public headerCustomClass!: string;

  @Prop({
    type: String,
    required: false,
    default: 'white'
  })
  private mode!: 'white' | 'gray';

  public toggle():void {
    this.isExpanded = !this.isExpanded;
  }

  public close(): void {
    if (this.isExpanded) this.isExpanded = false;
  }

  public open(): void {
    if (!this.isExpanded) this.isExpanded = true;
  }

  @Watch('isExpanded')
  public onIsActiveChanged(expanded: boolean): void {
    if (!expanded) this.$emit('closed');
  }

  private get classObject(): object {
    return {
      [`${this.mode}-mode`]: true,
      'active': this.isExpanded
    };
  }
}
