

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IMediaProductionInfo } from '@/services/api/media.service';
import { isNotEmptyArray } from '@/utilities';

@Component({})

export default class DetailsProduction extends Vue {
  private data: IMediaProductionInfo = this.$store.getters.getMediaProductionInfo;
  private isNotEmptyArray = isNotEmptyArray;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private wrapperCustomClass!: string;
}
