


































































































import Vue, {VueConstructor} from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IMedia, MediaType, MediaTypes, MediaTypesGroups, MediaExtension, IMediaRentalLinkResponse} from '@/services/api/media.service';
import {ICardType, IDictionary} from '@/interfaces/interfaces';

import IconButton from '@/components/UI/IconButton.vue';
import DocumentsIcon from '@/assets/icon/documents.svg';
import SoundIcon from '@/assets/icon/ico-sound.svg';
import PhotoIcon from '@/assets/icon/photo.svg';
import FilmIcon from '@/assets/icon/film.svg';
import WorksheetIcon from '@/assets/icon/ico-arbeitsblatt.svg';
import WorksheetInteractiveIcon from '@/assets/icon/ico-arbeitsblatt-interactive.svg';
import DidacticIcon from '@/assets/icon/ico-didaktik.svg';
import InteractiveIcon from '@/assets/icon/interactive.svg';
import SolutionSheetIcon from '@/assets/icon/ico-lösungsblatt.svg';
import SubjectInformationIcon from '@/assets/icon/ico-sachinfo.svg';
import ClipIcon from '@/assets/icon/clip.svg';
import MediaCollectionIcon from '@/assets/icon/media-collection.svg';
import WebsiteIcon from '@/assets/icon/website.svg';
import TeachingModuleIcon from '@/assets/icon/teaching-module.svg';
import DvdVideoIcon from '@/assets/icon/ico-film-dvd.svg';
import ApprovedIcon from '@/assets/icon/ico-approved.svg';
import OerIcon from '@/assets/icon/ico-oer.svg';
import PlusIcon from '@/assets/icon/ico-plus.svg';
import PlayIcon from '@/assets/icon/ico-play.svg';
import EyeIcon from '@/assets/icon/ico-eye.svg';
import DownloadIcon from '@/assets/icon/ico-download.svg';
import FileIcon from '@/assets/icon/ico-check-done.svg';
import LinkIcon from '@/assets/icon/ico-link.svg';

import CardCover from '@/components/UI/Card/CardCover.vue';
import Permission from '@/components/Permission/Permission.vue';

export const MediaTypeTitle: IDictionary<string> = {
  MEDIENSAMMLUNG: 'Online-Mediensammlung',
  UNTERRICHTSMODUL: 'Unterrichtsmodul',
  DVD_VIDEO: 'DVD-Video',
  FILM: 'Film',
  BILD: 'Bild',
  AUDIO: 'Audio',
  WEBSEITE: 'Webseite',
  ARBEITSMATERIAL: 'Arbeitsmaterial',
  INTERAKTION: 'Interaktion',
  SONSTIGES: 'Sonstiges',
  LOSUNGSBLATT: 'Lösungsblatt',
  ARBEITSBLATT: 'Arbeitsblatt',
  ARBEITSBLATT_INTERAKTIV: 'Interaktives Arbeitsblatt',
  DIDAKTIK_METHODIK: 'Didaktik/Methodik',
  SACHINFORMATION: 'Sachinfo'
};

export const MediaTypeIcon: IDictionary<VueConstructor<Vue>> = {
  MEDIENSAMMLUNG: MediaCollectionIcon,
  UNTERRICHTSMODUL: TeachingModuleIcon,
  DVD_VIDEO: DvdVideoIcon,
  FILM: FilmIcon,
  BILD: PhotoIcon,
  AUDIO: SoundIcon,
  WEBSEITE: WebsiteIcon,
  ARBEITSMATERIAL: DocumentsIcon,
  INTERAKTION: InteractiveIcon,
  SONSTIGES: ClipIcon,
  LOSUNGSBLATT: SolutionSheetIcon,
  ARBEITSBLATT: WorksheetIcon,
  ARBEITSBLATT_INTERAKTIV: WorksheetInteractiveIcon,
  DIDAKTIK_METHODIK: DidacticIcon,
  SACHINFORMATION: SubjectInformationIcon
};

export const MediaWatchIcon: IDictionary<VueConstructor<Vue>> = {
  'zip': EyeIcon, 'webdvd.zip': PlayIcon,
  'mp4': PlayIcon, 'mov': PlayIcon, 'flv': PlayIcon, 'mpg': PlayIcon, 'wmv': PlayIcon,
  'mp3': PlayIcon, 'ogg': PlayIcon,
  'jpg': EyeIcon, 'png': EyeIcon, 'tif': EyeIcon,
  'html': EyeIcon, 'htm': EyeIcon,
  'doc': EyeIcon, 'pdf': EyeIcon, 'docx': EyeIcon, 'ppt': EyeIcon, 'pptx': EyeIcon, 'xls': EyeIcon, 'xlsx': EyeIcon,
  'swf': EyeIcon, 'bin': EyeIcon, 'exe': EyeIcon, 'flipchart': EyeIcon, 'gxt': EyeIcon, 'iso': EyeIcon, 'jcw': EyeIcon,
  'lld': EyeIcon, 'lsc': EyeIcon, 'lso': EyeIcon, 'mbz': EyeIcon, 'mdb': EyeIcon, 'mtt': EyeIcon, 'piv': EyeIcon,
  'stk': EyeIcon, 'sxd': EyeIcon, 'sxi': EyeIcon, 'xtm': EyeIcon
};

@Component({
  components: {
    CardCover,
    IconButton,
    DocumentsIcon,
    WorksheetIcon,
    DidacticIcon,
    WorksheetInteractiveIcon,
    SubjectInformationIcon,
    SoundIcon,
    PhotoIcon,
    FilmIcon,
    InteractiveIcon,
    ClipIcon,
    MediaCollectionIcon,
    WebsiteIcon,
    DvdVideoIcon,
    TeachingModuleIcon,
    ApprovedIcon,
    OerIcon,
    PlusIcon,
    PlayIcon,
    EyeIcon,
    DownloadIcon,
    FileIcon,
    LinkIcon,
    Permission
  }
})

export default class Card extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public item!: IMedia;

  @Prop({
    type: String,
    required: false,
    default: 'high'
  })
  public cardType!: ICardType;

  private EyeIcon = EyeIcon;
  private PlayIcon = PlayIcon;
  private DownloadIcon = DownloadIcon;
  private PlusIcon = PlusIcon;
  private FileIcon = FileIcon;

  public type = this.item.loanableMedia
    ? this.item.loanableMediaType
    : this.item.mediaType === 'ARBEITSMATERIAL' && this.item.learningObjectTypes?.length
      ? this.item.learningObjectTypes?.join(', ')
      : MediaTypeTitle[this.item.mediaType as MediaType];
    
  public icon = this.item.loanable || null
    ? null
    : MediaTypeIcon[this.item.mediaType as MediaType];

  public group = MediaTypesGroups[this.item.mediaType as MediaType];

  public get watchIcon(): VueConstructor {
    if (this.item.externalObject) return LinkIcon;
    return this.item.dataInfo
      ? MediaWatchIcon[this.item.dataInfo.extension as MediaExtension]
      : EyeIcon;
  }

  public get watchVisible(): boolean {
    return this.$store.getters.canMediaView(this.item);
  }

  private get classObject(): IDictionary<boolean> {
    return {
      [`${this.cardType}-card`]: true
    };
  }

  public get addMediaAvailable(): boolean {
    return this.$route.name !== 'bookmarks';
  }

  private navigate(id: string): void {
    this.$router.push(
      { 
        path: `/details/${id}`, 
        query: this.$route.query.sid
          ? { sid: this.$route.query.sid }
          : {}
      }
    );
    
  }

  public openAddMediaModal(item: IMedia) {
    this.$root.$emit('openDetailsModal', 
      {
        id: 'addMedia', 
        mediaIdList: [item.id],
        media: item
      }
    );
  }

  private view(event: Event): void {
    event.stopPropagation();
    this.$store.dispatch('sendStatisticStream', this.item.id);

    if (this.item.externalObject) {
      this.$root.$emit('openFooterModal', {
        id: 'dataTransmission',
        mediaId: this.item.id,
        callback: 'openExternalMediaLink'
      });
      return;
    }

    if (this.item.mediaType === MediaTypes.WEBSEITE) {
      window.open(this.item.url, '_blank');
      return;
    }
    
    if (this.$store.getters.getMediaEmbedType(this.item)) {
      this.$root.$emit('openDetailsModal', {
        id: this.$store.getters.getMediaEmbedType(this.item),
        media: this.item
      });
    } else {
      this.$store.dispatch(
        'openLightbox',
        {
          url: this.$store.getters.isMediaDocumentViewAvailable(this.item) 
              ? this.item.dataInfo.versions.original.url
              : this.item.dataInfo.versions.preview.url,
          component: this.$store.getters.isMediaDocumentViewAvailable(this.item)
              ? 'pdf' 
              : 'image'
        }
      );
    }
  }

  public download(event: Event) {
    event.preventDefault();
    this.$store.dispatch('sendStatisticDownload', [this.item.id]);
    window.open(this.$store.getters.getMediaDownloadLink(this.item), '_blank');
  }

  public borrow(event: Event) {
     event.stopPropagation();
     if (!this.item.loanMailTo) {
       event.preventDefault();
       this.$store.dispatch('getRentalMediaLink', this.item.id)
        .then((data: IMediaRentalLinkResponse) => {
          if (!data) return;
          this.$root.$emit('openDetailsModal', {
            id: 'rental',
            media: this.item,
            rentalUrl: data.loanUrl
          });
        });
     }
  }
}
