























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BenefitsImage from '@/pages/Main/Benefits.section.image.vue';
import {IBenefit} from '@/services/data/benefit.service';

@Component({
  components: { BenefitsImage }
})
export default class Slide extends Vue {
  @Prop({
    required: true,
    type: Object
  })
  public item!: IBenefit;

  @Prop({
    required: true,
    type: String
  })
  public position!: string;

  public get element(): string {
    // eslint-disable-next-line no-prototype-builtins
    return this.item.hasOwnProperty('link') ? 'a' : 'div';
  }
}
