

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DetailsStandardsItem from '@/pages/Details/DetailsStandards.item.vue';

@Component({
  components: {
    DetailsStandardsItem
  }
})

export default class DetailsStandards extends Vue {
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private wrapperCustomClass!: string;
}
