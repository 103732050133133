










import Vue from 'vue';
import Component from 'vue-class-component';
import BackIcon from '@/assets/icon/back.svg';

@Component({
  components: {
    BackIcon
  }
})
export default class BackLink extends Vue {
  public goBack() {
    window.history.state
      ? this.$router.go(-1)
      : this.$router.push('/');
  }
  public get styleObject() {
    return {
      '--variant': this.$attrs.variant || '#74769a'
    };
  }
}
