









import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({})
export default class Badge extends Vue {

  @Prop({
    type: [Number, String],
    required: false,
    default: ''
  })
  public count!: number | string;

  @Prop({
    type: String,
    required: false,
    default: null
  })
  public label!: string | null;

  public get minCharWidth(): string {
    return `${this.count.toString().length}ch`;
  }
}
