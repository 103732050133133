






























import Vue from 'vue';
import Component from 'vue-class-component';
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper';
import { Prop } from 'vue-property-decorator';
import IconsSlide from './Icons.slide.vue';
import HeadSlide from './Head.slide.vue';
import CardSlide from './Card.slide.vue';
import Chevron from '@/components/UI/Icons/Chevron.vue';
import {ISlide} from '@/services/api/head-slider.service';

export enum PositionEnum {
  icons_position = 'icons_position',
  benefits = 'benefits',
  header = 'header',
  charts = 'charts',
  offers = 'offers'
}

export type typedPosition = keyof typeof PositionEnum;

@Component({
  components: {
    Swiper,
    SwiperSlide,
    IconsSlide,
    HeadSlide,
    CardSlide,
    Chevron
  },
  directives: {
    swiper: directive
  },
  computed: {
    swiper() {
      return this.$refs.swiper;
    },
  }
})

export default class Slider extends Vue {
  public $refs!: {
    swiper: any
  }

  @Prop({
    required: false,
    type: Array,
    default: () => []
  })
  public listItem!: ISlide[];

  @Prop({
    required: true,
    type: String,
  })
  public position!: typedPosition; // TODO WTF??? Refactor it!

  @Prop({
    required: true,
    type: Number,
    default: 1
  })
  public slidesPerView!: number;

  @Prop({
    required: true,
    type: String
  })
  public pagination!: string;

  @Prop({
    required: false,
    type: Object,
    default: () => {
      return {
        320: {
          slidesPerView: 1
        }
      };
    }
  })
  public breakpoints!: object;

  @Prop({
    required: false,
    type: String,
  })
  public sliderHeight!: string;

  @Prop({
    required: false,
    type: Boolean,
    default: false
  })
  public autoplay!: boolean;

  @Prop({
    required: false,
    type: Object,
    default: () => ({})
  })
  public autoPlayConfigs!: object;

  swiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: this.pagination,
      bulletClass: 'swiper-bullet',
      bulletActiveClass: 'swiper-bullet-active'
    },
    slidesPerView: this.slidesPerView,
    effect: 'slide',
    breakpoints: this.breakpoints,
    autoplay: this.autoPlayConfigs ? this.autoPlayConfigs : this.autoplay
  }

  public get classObject() {
    return {
      [`${this.sliderHeight}-height`]: this.sliderHeight
    };
  }

  get activeIndex() {
    return this.$refs.swiper.activeIndex;
  }

  public timeId: number = 0;
  pauseAutoPlay() {
    if (this.position === 'header') {
      this.$refs.swiper.$swiper.autoplay.stop();
      clearTimeout(this.timeId);
      this.timeId = window.setTimeout(() => {
        this.$refs.swiper.$swiper.autoplay.start();
      }, 10000);
    }
    return;
  }
}
