





























  import Vue from 'vue';
  import Component from 'vue-class-component';
  import {createHelpers} from 'vuex-map-fields';
  import Card from '@/components/UI/Card/Card.vue';
  import SectionHeader from '@/pages/Main/Section.header.vue';
  const { mapFields } = createHelpers({
    getterType: 'getChartField',
    mutationType: 'updateChartField',
  });

@Component({
    components: {
      SectionHeader,
      Card
    },
    computed: {
      ...mapFields([
        'chartList'
      ])
    }
  })
  export default class Chart extends Vue {
    public created() {
      this.$store.dispatch('getChartList');
    }
  }
