





































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {IMedia, MediaGroups, MediaTypesGroups, MediaType, MediaTypes, MediaExtension, IMediaRentalLinkResponse} from '@/services/api/media.service';
import {MediaWatchIcon} from '@/components/UI/Card/Card.vue';
import Permission from '@/components/Permission/Permission.vue';
import BookmarksForm from '@/pages/Bookmarks/Bookmarks.form.vue';
import Iframe from '@/components/UI/Iframe.vue';
import Video from '@/components/UI/Video.vue';

import EyeIcon from '@/assets/icon/ico-eye.svg';
import DownloadIcon from '@/assets/icon/ico-download.svg';
import PlusIcon from '@/assets/icon/ico-plus.svg';
import PlayIcon from '@/assets/icon/ico-play.svg';
import FileIcon from '@/assets/icon/ico-check-done.svg';
import LinkIcon from '@/assets/icon/ico-link.svg';
import EmbedIcon from '@/assets/icon/embed.svg';

@Component({
  components: {
    Permission,
    BookmarksForm,
    Iframe,
    Video
  }
})

export default class DetailsButtons extends Vue {
  private EyeIcon = EyeIcon;
  private PlayIcon = PlayIcon;
  private DownloadIcon = DownloadIcon;
  private PlusIcon = PlusIcon;
  private FileIcon = FileIcon;
  private LinkIcon = LinkIcon;
  private EmbedIcon = EmbedIcon;

  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public single!: boolean;

  public group = MediaTypesGroups[this.$store.getters.getMediaType as MediaType];
  public MediaGroups = MediaGroups;

  public get watchIcon() {
    if (this.data.externalObject) return LinkIcon;
    return this.data.dataInfo
      ? MediaWatchIcon[this.data.dataInfo.extension as MediaExtension]
      : EyeIcon;
  }

  public get isWatchAvailable(): boolean {
    if (this.data.mediaType === MediaTypes.MEDIENSAMMLUNG
      && !this.data.dataInfo && this.data.url) return true;
    if (this.data.mediaType === MediaTypes.AUDIO ) return false;
    if (this.data.mediaType === MediaTypes.FILM ) return false;
      return this.$store.getters.canMediaView(this.data);
  }

  public openAddMediaModal(data: IMedia) {
    this.$root.$emit('openDetailsModal', 
      {
        id: 'addMedia', 
        mediaIdList: [data.id],
        media: data
      }
    );
  }

  public view(event: Event): void {
    event.preventDefault();
    this.$store.dispatch('sendStatisticStream', this.data.id);

    if (this.data.externalObject) {
      this.$root.$emit('openFooterModal', {
        id: 'dataTransmission',
        mediaId: this.data.id,
        callback: 'openExternalMediaLink'
      });
      return;
    }

    if (this.data.mediaType === MediaTypes.WEBSEITE) {
      window.open(this.data.url, '_blank');
      return;
    }

    if (this.data.mediaType === MediaTypes.MEDIENSAMMLUNG
      && !this.data.dataInfo && this.data.url) {
      window.open(this.data.url, '_blank');
      return;
    }
    
    if (this.$store.getters.getMediaEmbedType(this.data)) {
      this.$root.$emit('openDetailsModal', {
        id: this.$store.getters.getMediaEmbedType(this.data),
        media: this.data
      });
    } else {
      this.$store.dispatch(
        'openLightbox',
        {
          url: this.$store.getters.isMediaDocumentViewAvailable(this.data)
            ? this.data.dataInfo.versions.original.url
            : this.data.dataInfo.versions.preview.url,
          component: this.$store.getters.isMediaDocumentViewAvailable(this.data)
            ? 'pdf' 
            : 'image'
        }
      );
    }
  }

  public download(event: Event) {
    event.preventDefault();
    this.$store.dispatch('sendStatisticDownload', [this.data.id]);
    window.open(this.$store.getters.getMediaDownloadLink(this.data), '_blank');
  }

  public borrow(event: Event) {
     event.stopPropagation();
     if (!this.data.loanMailTo) {
       event.preventDefault();
       this.$store.dispatch('getRentalMediaLink', this.data.id)
        .then((data: IMediaRentalLinkResponse) => {
          if (!data) return;
          this.$root.$emit('openDetailsModal', {
            id: 'rental',
            media: this.data,
            rentalUrl: data.loanUrl
          });
        });
     }
  }

  private get sharingAvailable() {
    // Check user rights
    if (!this.$store.getters.sharingMediaPermission) return false;
    // Check embedding and streaming availablility
    return this.$store.getters.canMediaEmbed(this.data) || this.data.assetLink;
  }
}
