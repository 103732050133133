



















import {
  Vue,
  Component,
  Prop
} from 'vue-property-decorator';
import Slider from '@/components/Slider/Slider.vue';
import SectionHeader from '@/pages/Main/Section.header.vue';
import {createHelpers} from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'getChartField',
  mutationType: 'updateChartField',
});

@Component({
  components: {
    Slider,
    SectionHeader
  },
  computed: {
    ...mapFields([
      'chartList'
    ])
  }
})
export default class CardSlider extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: () => ({
      320: {
        slidesPerView: 1,
        spaceBetween: 8
      },
      375: {
        slidesPerView: 1.2,
        spaceBetween: 1
      },
      460: {
        slidesPerView: 1.5,
        spaceBetween: 16
      },
      640: {
        slidesPerView: 2.2,
        spaceBetween: 16
      },
      768: {
        slidesPerView: 2.2,
        spaceBetween: 16
      },
    })
  })
  private mobileBreakpoints!: object;

  @Prop({
    type: Object,
    required: false,
    default: () => ({
      769: {
        slidesPerView: 2,
        spaceBetween: 16
      },
      840: {
        slidesPerView: 2.2,
        spaceBetween: 16
      },
      980: {
        slidesPerView: 2,
        spaceBetween: 16
      },
      981: {
        slidesPerView: 2.2,
        spaceBetween: 10
      },
      1024: {
        slidesPerView: 2.6,
        spaceBetween: 24
      }
    })
  })
  private tabletBreakpoints!: object;

  @Prop({
    type: Object,
    required: false,
    default: () => ({
      1280: {
        slidesPerView: 3.1,
        spaceBetween: 10
      },
      1460: {
        slidesPerView: 3.5,
        spaceBetween: 10
      }
    })
  })
  private desktopBreakpoints!: object;

  public get breakpoints(): object {
    return {
      ...this.mobileBreakpoints,
      ...this.tabletBreakpoints,
      ...this.desktopBreakpoints,
    };
  }

  public created() {
    this.$store.dispatch('getChartList');
  }
}
