
































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IMedia, MediaType, MediaTypesGroups, MediaGroups} from '@/services/api/media.service';

import ApprovedIcon from '@/assets/icon/ico-approved.svg';
import OerIcon from '@/assets/icon/ico-oer.svg';
import CCIcon from '@/assets/icon/ico-cc.svg';


@Component({
  components: {
    ApprovedIcon,
    OerIcon,
    CCIcon
  }
})

export default class DetailsLabels extends Vue {
  public MediaGroups = MediaGroups;
  public group = MediaTypesGroups[this.$store.getters.getMediaType as MediaType];

  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;

  @Prop({
    type: String,
    required: false,
    default: 'dark'
  })
  public mode!: 'dark' | 'light';

  private get classObject(): object {
    return {
      [`${this.mode}-mode`]: true,
    };
  }
}
