


















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})

export default class HeightTransition extends Vue {
  beforeEnter(element: HTMLElement) {
    requestAnimationFrame(() => {
      if (!element.style.height) {
        element.style.height = '0px';
      }

      element.style.display = '';
    });
  }
  enter(element: HTMLElement) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        element.style.height = `${element.scrollHeight}px`;
      });
    });
  }
  afterEnter(element: HTMLElement) {
    element.style.height = '';
  }
  beforeLeave(element: HTMLElement) {
    requestAnimationFrame(() => {
      if (!element.style.height) {
        element.style.height = `${element.offsetHeight}px`;
      }
    });
  }
  leave(element: HTMLElement) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        element.style.height = '0px';
      });
    });
  }
  afterLeave(element: HTMLElement) {
    element.style.height = '';
  }
}
