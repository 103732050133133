













































import Vue, {VueConstructor} from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {MediaType} from '@/services/api/media.service';
import DocumentsIcon from '@/assets/icon/documents.svg';
import SoundIcon from '@/assets/icon/ico-sound.svg';
import PhotoIcon from '@/assets/icon/photo.svg';
import FilmIcon from '@/assets/icon/film.svg';
import InteractiveIcon from '@/assets/icon/interactive.svg';
import WorksheetIcon from '@/assets/icon/ico-arbeitsblatt.svg';
import WorksheetInteractiveIcon from '@/assets/icon/ico-arbeitsblatt-interactive.svg';
import DidacticIcon from '@/assets/icon/ico-didaktik.svg';

import SubjectInformationIcon from '@/assets/icon/ico-sachinfo.svg';
import ClipIcon from '@/assets/icon/clip.svg';
import MediaCollectionIcon from '@/assets/icon/media-collection.svg';
import WebsiteIcon from '@/assets/icon/website.svg';
import TeachingModuleIcon from '@/assets/icon/teaching-module.svg';
import {MediaTypeIcon} from '@/components/UI/Card/Card.vue';

@Component({
  components: {
    DocumentsIcon,
    SoundIcon,
    PhotoIcon,
    FilmIcon,
    InteractiveIcon,
    WorksheetIcon,
    DidacticIcon,
    WorksheetInteractiveIcon,
    SubjectInformationIcon,
    ClipIcon,
    MediaCollectionIcon,
    WebsiteIcon,
    TeachingModuleIcon
  }
})
export default class CardCoverPlaceholder extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private type!: MediaType;

  public icon: null | VueConstructor = MediaTypeIcon[this.type as MediaType] || null;
}
