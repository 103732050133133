








import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({})
export default class SectionHeader extends Vue {
  @Prop({
    type: String,
    required: false,
    default: null
  })
  public subtitle!: string | null;

  @Prop({
    type: String,
    required: true,
  })
  public title!: string;

  @Prop({
    type: [String, Boolean],
    required: false,
    default: null
  })
  public reverse!: string | boolean;

  private get rootClasses() {
    return {
      'reversed': typeof this.reverse !== undefined
    };
  }
}
