
























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IMediaFurtherInfo } from '@/services/api/media.service';
import {isNotEmptyArray} from '@/utilities';

@Component({})

export default class DetailsFurtherInfo extends Vue {
  private data: IMediaFurtherInfo = this.$store.getters.getMediaFurtherInfo;
  private isNotEmptyArray = isNotEmptyArray;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private wrapperCustomClass!: string;
}
