









import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({})
export default class TextCollapse extends Vue {
  private scrollHeight: null | number = null;
  private maxHeight: null | number = null;
  private isExpanded: boolean = false;

  @Prop({
    type: String,
    required: true
  })
  public content!: string;

  @Prop({
    type: String,
    required: false,
    default: 'Mehr lesen'
  })
  public expandText!: string;

  @Prop({
    type: String,
    required: false,
    default: 'Minimieren'
  })
  public collapseText!: string;

  @Prop({
    type: Number,
    required: false,
    default: 690
  })
  public maxWidth!: number;

  @Prop({
    type: Number,
    required: false,
    default: 75
  })
  public height!: number;

  @Prop({
    type: String,
    required: false,
    default: 'linear-gradient(to bottom, rgba(243, 244, 248, 0), rgba(243, 244, 248, 0.8) 44%, #F3F4F8 91%)'
  })
  public coverGradient!: string;

  public mounted() {
    this.maxHeight = this.height;
    this.scrollHeight = (this.$refs.content as HTMLInputElement).scrollHeight;
  }

  private get classObject(): object {
    return {
      'expanded': this.isExpanded
    };
  }

  private get styleObject(): object {
    return {
      '--max-width': `${this.maxWidth}px`,
      '--max-height': `${this.maxHeight}px`,
      '--cover-gradient': this.isDisabled ? 'none' : this.coverGradient,
      '--transition-time': '.2s' 
    };
  }

  public get isDisabled() {
    return this.scrollHeight && this.height >= this.scrollHeight;
  }

  public toggle():void {
    this.isExpanded = !this.isExpanded;
    this.isExpanded 
      ? this.maxHeight = this.scrollHeight 
      : this.maxHeight = this.height;
  }
}
