


































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {IMedia, MediaType} from '@/services/api/media.service';
import {ICardType} from '@/interfaces/interfaces';
import {mediaItems, mediaListItem} from '@/pages/Details/Details.collection.vue';

import AccordionItem from '@/components/UI/Accordion/AccordionItem.vue';
import DetailsMediaListItem from '@/pages/Details/DetailsMediaList.item.vue';
import Badge from '@/components/UI/Badge.vue';

import EditIcon from '@/assets/icon/edit.svg';
import DownloadIcon from '@/assets/icon/ico-download.svg';
import PlusIcon from '@/assets/icon/ico-plus.svg';
import CloseIcon from '@/assets/icon/input-close.svg';

@Component({
  components: {
    AccordionItem,
    DetailsMediaListItem,
    Badge
  }
})

export default class DetailsMediaList extends Vue {
  public EditIcon = EditIcon;
  public DownloadIcon = DownloadIcon;
  public PlusIcon = PlusIcon;
  public CloseIcon = CloseIcon;

  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;

  public mediaItems: mediaListItem[] = mediaItems;

  public mediaListByType(type: MediaType): [] {
    return this.$store.getters.getNestedMediaListByType(type);
  }

  public itemsInRow(item: mediaListItem): number {
    return item.cardType === 'high' as ICardType ? 8 : 9;
  }

  public activateEditMode(item: mediaListItem) {
    const accordionItems = (this.$refs.items as Vue[])
      .filter(element => element.$vnode.data 
        ? element.$vnode.data.key === item.type 
        : []
      );
    if (accordionItems[0]) (accordionItems[0] as AccordionItem).open();
    item.editMode = true;
  }

  public deactivateEditMode(item: mediaListItem) {
    item.editMode = false;
    item.isAllSelected = false;
    item.selectedIdList = [];
  }

  public mediaList(item: mediaListItem) {
    return this.mediaListByType(item.type);
  }

  public count(item: mediaListItem): string {
    return item.editMode
      ? `${item.selectedIdList.length + ' / ' + this.mediaListByType(item.type).length}`
      : `${this.mediaListByType(item.type).length}`;
  }

  public setAllMediaIdList(item: mediaListItem) {
    const list = this.mediaListByType(item.type)
      .map((item : IMedia) => item.id);
    if (!item.isAllSelected) {
      item.isAllSelected = true;
      item.selectedIdList = list;
    } else {
      item.isAllSelected = false;
      item.selectedIdList = [];
    }
  }

  public isMediaListDownloadAvailable(item: mediaListItem): boolean {
    return !this.data.nestedObjects
      .filter(media => media.mediaType === item.type)
      .some(item => !item.download);
  }

  public isEditModeAvailable(item: mediaListItem): boolean {
    return this.$store.getters.permission('useBookmarks') 
      || this.isMediaListDownloadAvailable(item);
  }

  public addMedia(item: mediaListItem) {
    item.selectedIdList[0]
      ? this.$root.$emit('openDetailsModal', {
        // 'addMedia', 
        //  item.selectedIdList, 
        //  item.type,
        id: 'addMedia', 
        mediaIdList: item.selectedIdList
      })
      : this.notifyMediaNotSelected();
  }

  public downloadMedia(event: Event, item: mediaListItem) {
    event.preventDefault();
    if (item.selectedIdList[0]) {
      this.$store.dispatch('sendStatisticDownload', item.selectedIdList);
      window.open(this.$store.getters.getMediaListDownloadLink(item.selectedIdList), '_blank');
      return;
    }
    this.notifyMediaNotSelected();
  }

  public notifyMediaNotSelected() {
    this.$notify({
      group: 'app',
      type: 'warning',
      title: 'Medien',
      text: 'Bitte wählen Sie die gewünschten Medien'
    });
  }
}
