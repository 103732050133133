






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { mediaListItem } from '@/pages/Details/Details.collection.vue';
import { IMedia } from '@/services/api/media.service';
import Card from '@/components/UI/Card/Card.vue';


@Component({
  components: { Card },
})
export default class BookmarkMediaList extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public data!: mediaListItem;

   @Prop({
    type: Object,
    required: true
  })
  public item!: IMedia;
}
