








import Vue, {VueConstructor} from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';
import {IMedia, MediaTypesGroups, MediaType} from '@/services/api/media.service';
import { IDictionary } from '@/interfaces/interfaces';
import DetailsStream from '@/pages/Details/Details.stream.vue';
import DetailsError from '@/pages/Details/Details.error.vue';
import DetailsCollection from '@/pages/Details/Details.collection.vue';
import DetailsDocument from '@/pages/Details/Details.document.vue';

const { mapFields } = createHelpers({
  getterType: 'getMediaField',
  mutationType: 'updateMediaField',
});

@Component({
  components: {
    DetailsStream,
    DetailsCollection,
    DetailsDocument,
    DetailsError
  },
  computed: {...mapFields([
      'mediaData'
    ]),
  }
})
export default class Details extends Vue {
  private mediaData!: IMedia | null;
  private mediaComponent: null | VueConstructor<Vue> = null;

  private componentDictionary: IDictionary<VueConstructor<Vue>> = {
    DOCUMENT: DetailsDocument,
    STREAM: DetailsStream,
    COLLECTION: DetailsCollection,
  }

  private created() {
    this.setData();
  }

  @Watch('$route.params.id')
  private onRouteChanged() {
    this.setData();
  }

  @Watch('$store.state.user.user')
  private onMediaDataChanged() {
    if (!this.$store.state.user.isAnonymousUser) {
      this.setData();
    }
  }

  private setData() {
    const id = +this.$route.params.id;
    this.$store.dispatch('getMediaData', {id})
      .then(() => {
        const mediaType = this.$store.getters.getMediaType;
        this.mediaComponent = this.componentDictionary[MediaTypesGroups[mediaType as MediaType]] || DetailsError;
      });
  }
}
