import { render, staticRenderFns } from "./BackLink.vue?vue&type=template&id=30c64c3b&scoped=true&lang=pug&"
import script from "./BackLink.vue?vue&type=script&lang=ts&"
export * from "./BackLink.vue?vue&type=script&lang=ts&"
import style0 from "./BackLink.vue?vue&type=style&index=0&id=30c64c3b&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c64c3b",
  null
  
)

export default component.exports