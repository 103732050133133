











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IMediaStandard } from '@/services/api/media.service';

@Component({name: 'DetailsStandardsItem'})

export default class DetailsStandardsItem extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public item!: IMediaStandard;
}
