














import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {MediaType} from '@/services/api/media.service';
import CardCoverPlaceholder from '@/components/UI/Card/CardCoverPlaceholder.vue';

@Component({
  components: {
    CardCoverPlaceholder
  }
})
export default class CardCover extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private type!: MediaType;

  @Prop({
    type: String,
    default: '',
    required: true
  })
  private coverLink!: string;

  @Prop({
    type: String,
    default: 'high',
    required: false
  })
  private cardType!: 'high' | 'wide';

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private externalObject!: boolean;

  private get classObject(): object {
    return {
      [`${this.cardType}-card`]: !this.externalObject,
      'external': this.externalObject
    };
  }
}
