


















































































import Vue, {VueConstructor} from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {IMedia, MediaType, MediaTypes} from '@/services/api/media.service';
import {MediaTypeTitle, MediaTypeIcon} from '@/components/UI/Card/Card.vue';
import {ICardType} from '@/interfaces/interfaces';

import BackLink from '@/components/UI/BackLink.vue';
import Badge from '@/components/UI/Badge.vue';
import TextCollapse from '@/components/UI/TextCollapse.vue';
import Chart from '@/pages/Main/Chart.section.vue';
import DetailsLabels from '@/pages/Details/DetailsLabels.block.vue';
import DetailsButtons from '@/pages/Details/DetailsButtons.block.vue';
import DetailsMediaList from '@/pages/Details/DetailsMediaList.block.vue';
import DetailsProduction from '@/pages/Details/DetailsProduction.block.vue';
import DetailsDidactic from '@/pages/Details/DetailsDidactic.block.vue';
import DetailsStandards from '@/pages/Details/DetailsStandards.block.vue';
import DetailsPlaceholder from '@/pages/Details/DetailsPlaceholder.block.vue';
import DetailsFurtherInfo from '@/pages/Details/DetailsFurtherInfo.block.vue';
import DetailsModals from '@/pages/Details/DetailsModals.vue';
import Permission from '@/components/Permission/Permission.vue';

import stringToHtml from '@/filters/stringToHtml';

type detailsTabsItem = {
  title: string;
  component: VueConstructor<Vue>;
  slot: object | [];
};

export type mediaListItem = {
  type: MediaType;
  title: string;
  cardType: ICardType;
  editMode: boolean;
  selectedIdList: number[];
  isAllSelected: boolean;
};

export const mediaItems: mediaListItem[] = [
  {
    type: MediaTypes.ARBEITSBLATT,
    title: 'Arbeitsblatt',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.ARBEITSBLATT_INTERAKTIV,
    title: 'Arbeitsblatt interaktiv',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.ARBEITSMATERIAL,
    title: 'Arbeitsmaterial',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.AUDIO,
    title: 'Audio',
    cardType: 'high',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.BILD,
    title: 'Bilder',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.DIDAKTIK_METHODIK,
    title: 'Didaktik methodik',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.INTERAKTION,
    title: 'Interaktion',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.LOSUNGSBLATT,
    title: 'Lösungsblatt',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.FILM,
    title: 'Filme',
    cardType: 'high',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.SACHINFORMATION,
    title: 'Sachinformation',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.SONSTIGES,
    title: 'Sonstiges',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.UNTERRICHTSMODUL,
    title: 'Unterrichtsmodul',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  },
  {
    type: MediaTypes.WEBSEITE,
    title: 'Website',
    cardType: 'wide',
    editMode: false,
    selectedIdList: [],
    isAllSelected: false
  }
];

@Component({
  components: {
    BackLink,
    Badge,
    TextCollapse,
    DetailsLabels,
    DetailsButtons,
    DetailsMediaList,
    DetailsProduction,
    DetailsDidactic,
    DetailsStandards,
    DetailsFurtherInfo,
    DetailsPlaceholder,
    DetailsModals,
    Permission,
    Chart
  },
  filters: { stringToHtml }
})

export default class DetailsCollection extends Vue {
  public mediaItems = mediaItems;
  
  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;

  private detailsTabs: detailsTabsItem[] = [
    {
      title: 'Medien',
      component: DetailsMediaList,
      slot: this.data.nestedObjects
    },
    {
      title: 'Produktion',
      component: DetailsProduction,
      slot: this.data.productionInformation
    },
    {
      title: 'Didaktische Hinweise',
      component: DetailsDidactic,
      slot: this.data.didacticNotes
    },
    {
      title: 'Bildungsstandards',
      component: DetailsStandards,
      slot: this.data.standardList
    },
    {
      title: 'Weitere Infos',
      component: DetailsFurtherInfo,
      slot: this.data.furtherInformation
    }
  ]

  public mounted() {
    this.$root.$on('clearMediaIdList', (mediaItemType: MediaType) => this.clearMediaIdList(mediaItemType));
  }

  public type = this.data.loanable
    ? this.data.loanableMediaType
    : MediaTypeTitle[this.data.mediaType as MediaType];

  public icon = this.data.loanable
    ? null
    : MediaTypeIcon[this.data.mediaType as MediaType];

  public isTabItemEmpty(tab: detailsTabsItem): boolean {
    return !Object.keys({...tab.slot})[0];
  }

  public clearMediaIdList(mediaItemType: MediaType) {
    const filteredItems = this.mediaItems
      .filter(item => item.type === mediaItemType);
    if (filteredItems[0]) {
      filteredItems[0].selectedIdList = [];
      filteredItems[0].isAllSelected = false;
    }
  }
}
