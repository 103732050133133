import { render, staticRenderFns } from "./DetailsMediaList.block.vue?vue&type=template&id=8ce295b8&scoped=true&lang=pug&"
import script from "./DetailsMediaList.block.vue?vue&type=script&lang=ts&"
export * from "./DetailsMediaList.block.vue?vue&type=script&lang=ts&"
import style0 from "./DetailsMediaList.block.vue?vue&type=style&index=0&id=8ce295b8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ce295b8",
  null
  
)

export default component.exports