






import Vue from 'vue';
import Component from 'vue-class-component';
import {MediaType} from '@/services/api/media.service';
import {MediaTypeIcon} from '@/components/UI/Card/Card.vue';

@Component({})

export default class DetailsPlaceholder extends Vue {
  public icon = MediaTypeIcon[this.$store.getters.getMediaType as MediaType];
}
