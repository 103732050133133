
















import Vue, {VueConstructor} from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {IMedia} from '@/services/api/media.service';

import AccordionItem from '@/components/UI/Accordion/AccordionItem.vue';
import DetailsDidactic from '@/pages/Details/DetailsDidactic.block.vue';
import DetailsStandards from '@/pages/Details/DetailsStandards.block.vue';
import DetailsFurtherInfo from '@/pages/Details/DetailsFurtherInfo.block.vue';
import DetailsProduction from '@/pages/Details/DetailsProduction.block.vue';

type accordionItem = {
  title: string;
  component: VueConstructor<Vue>;
  slot: object | [];
};

@Component({
  components: {
    AccordionItem,
    DetailsDidactic,
    DetailsStandards,
    DetailsFurtherInfo,
    DetailsProduction
  }
})

export default class DetailsMainInfo extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;

  private accordionItems: accordionItem[] = [
    {
      title: 'Produktion',
      component: DetailsProduction,
      slot: this.data.productionInformation
    },
    {
      title: 'Didaktische Hinweise',
      component: DetailsDidactic,
      slot: this.data.didacticNotes
    },
    {
      title: 'Bildungsstandards',
      component: DetailsStandards,
      slot: this.data.standardList
    },
    {
      title: 'Weitere Informationen',
      component: DetailsFurtherInfo,
      slot: this.data.furtherInformation
    }
  ]

  public hasValues(item: accordionItem): boolean {
    return !!Object.keys({...item.slot})[0] 
      && !!Object.values({...item.slot}).filter(value => !!value && String(value) !== '')[0];
  }
}
